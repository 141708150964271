import { getSubscription, getSubscriptions } from '@/api/user';
import { PinsaSubscription } from '@/types/pinsaTypes';
import { useQuery } from '@tanstack/vue-query';
import dayjs from 'dayjs';

const useGetSubscriptions = () => {
  const { data: subs, refetch: getUserSubscriptions } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: () => getSubscriptions(),
    enabled: true,
    retry: 0,
  });

  const subscriptions = computed(() => subs.value?.data?.data);
  const enabled = computed(() => !!subscriptions.value);
  const activeSubscription = computed(() => {
    const hasState = (state?: string) => {
      if (!state) return false;
      return ['paused', 'active', 'oos', 'discontinued'].includes(state);
    };
    if (!subscriptions.value) return null;
    return (
      subscriptions.value.find((subs: PinsaSubscription) => hasState(subs.attributes.state)) ||
      subscriptions.value.sort((a: PinsaSubscription, b: PinsaSubscription) => {
        const date1 = dayjs(a.attributes.cancellation_date);
        const date2 = dayjs(b.attributes.cancellation_date);
        return date1.isBefore(date2) ? 1 : -1;
      })[0]
    );
  });

  const { data: selectedSubscription } = useQuery({
    queryKey: ['subscriptionById', activeSubscription.value?.id ?? ''],
    queryFn: () => getSubscription(activeSubscription.value?.id ?? ''),
    enabled,
  });

  return {
    activeSubscription,
    subscriptions,
    selectedSubscription,
    getUserSubscriptions,
  };
};

export default useGetSubscriptions;
